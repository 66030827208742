<template>
  <v-dialog v-model="dialogState" persistent max-width="1200px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-account-search</v-icon>
          {{$vuetify.lang.t('$vuetify.buttons.activity_log')}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-8">
        <Table
          :servicePath="servicePath"
          :headers="tableHeaders"
          :reload="reload"
          tableType="activityLog"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{$vuetify.lang.t('$vuetify.close')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Table from "@/components/shared/Table";
export default {
  components: {
    Table
  },
  data: function() {
    return {
      dialogState: false,
      managerData: false,
      servicePath: false,
      reload: false
    };
  },
  methods: {
    closePopup() {
      this.dialogState = false;
      this.$emit("closePopup");
    }
  },
  watch: {
    popupActivity(newVal) {
      this.dialogState = newVal;
      this.reload = newVal
    },
    activityUserId(newVal) {
      this.servicePath = 'admin/user/activity/'+newVal
    }
  },
  props: ["popupActivity", "activityUserId"],
  computed: {
    tableHeaders() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.manager.username"),
          value: "user.username"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manager.activity"),
          value: "activity_type"
        },
        {
          text: "IP",
          value: "ip"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.date"),
          value: "activity_time"
        }
      ];
    }
  }
};
</script>