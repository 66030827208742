<template>
  <v-dialog v-model="dialogState" persistent max-width="800px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-account-plus</v-icon>
          {{$vuetify.lang.t('$vuetify.manager.create_manager')}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row align="start">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.username')+ ' *'"
                v-model="newItem.username"
                :error="validator.username.error_show"
                :error-messages="validator.username.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-select
                :label="$vuetify.lang.t('$vuetify.manager.role')+ ' *'"
                :items="roles"
                v-model="newItem.role"
                :error="validator.role.error_show"
                :error-messages="validator.role.error_msg"
                @keyup="validateForm"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="roles">{{ roles.item.value }}</template>
                <template slot="item" slot-scope="roles">{{ roles.item.value }}</template>
              </v-select>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.password')+ ' *'"
                v-model="newItem.password"
                :error="validator.password.error_show"
                :error-messages="validator.password.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.password_confirm')+ ' *'"
                v-model="newItem.password_confirm"
                :error="validator.password_confirm.error_show"
                :error-messages="validator.password_confirm.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.email')+ ' *'"
                v-model="newItem.email"
                :error="validator.email.error_show"
                :error-messages="validator.email.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{$vuetify.lang.t('$vuetify.action.cancel')}}
        </v-btn>
        <v-btn @click="createManager" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{$vuetify.lang.t('$vuetify.action.create')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import ManagerHelper from "@/helpers/manager";
export default {
  data: function() {
    return {
      dialogState: false,
      newItem: ManagerHelper.newItemModel(),
      validator: ManagerHelper.validatorModel(),
      roles: []
    };
  },
  methods: {
    validateForm() {
      this.validator = ManagerHelper.validateForm(
        this.newItem.username,
        this.newItem.email,
        this.newItem.password,
        this.newItem.password_confirm
      )
    },
    closePopup() {
      this.dialogState = false;
      this.$emit("closePopup");
      this.validator = ManagerHelper.validatorModel();
      this.newItem = ManagerHelper.newItemModel();
    },
    addManager(newManager) {
      this.$parent.managers.push(newManager);
    },
    createManager() {
      Api.manager("create", this.newItem)
        .then(({ data }) => {
          this.$toast.ok(
            this.$vuetify.lang.t("$vuetify.manager.account_created") +
              " " +
              this.$vuetify.lang.t("$vuetify.manager.activation_email_sent")
          );

          this.$emit("managerAdded", data);
          this.closePopup();
        })
        .catch(error => {
          if (
            error.response.status == 400 &&
            typeof error.response.data.field !== "undefined"
          ) {
            this.validator.username.error_show = true;
            this.validator.username.error_msg = this.$i18n.messages[
              this.$i18n.locale
            ]["$vuetify"]["service_field_errors"][error.response.data.error];
          }
        });
    }
  },
  watch: {
    popupAddManager: function(newVal) {
      this.dialogState = newVal;
      this.roles = this.$i18n["messages"][this.$i18n.locale]["$vuetify"][
        "manager_roles"
      ];
    }
  },
  props: ["popupAddManager"]
};
</script>