<template>
  <v-dialog v-model="dialogState" persistent max-width="600px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-account-edit</v-icon>
          {{$vuetify.lang.t('$vuetify.manager.update_manager')}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row align="start">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.username')+ ' *'"
                v-model="managerData.username"
                :error="validator.username.error_show"
                :error-messages="validator.username.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.email')+ ' *'"
                v-model="managerData.email"
                :error="validator.email.error_show"
                :error-messages="validator.email.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-select
                :label="$vuetify.lang.t('$vuetify.manager.role')+ ' *'"
                :items="roles"
                v-model="managerData.role"
                :error="validator.role.error_show"
                :error-messages="validator.role.error_msg"
                @keyup="validateForm"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="roles">{{ roles.item.value }}</template>
                <template slot="item" slot-scope="roles">{{ roles.item.value }}</template>
              </v-select>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-select
                :label="$vuetify.lang.t('$vuetify.manager.status')+ ' *'"
                :items="statuses"
                v-model="managerData.status"
                :error="validator.status.error_show"
                :error-messages="validator.status.error_msg"
                @keyup="validateForm"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="statuses">{{ statuses.item.value }}</template>
                <template slot="item" slot-scope="statuses">{{ statuses.item.value }}</template>
              </v-select>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.new_pass')"
                v-model="managerData.new_pass"
                :error="validator.new_pass.error_show"
                :error-messages="validator.new_pass.error_msg"
                @keyup="validateForm"
                type="password"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.password_confirm')"
                v-model="managerData.re_new_pass"
                :error="validator.re_new_pass.error_show"
                :error-messages="validator.re_new_pass.error_msg"
                @keyup="validateForm"
                type="password"
              ></v-text-field>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{$vuetify.lang.t('$vuetify.action.cancel')}}
        </v-btn>
        <v-btn @click="updateManager" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{$vuetify.lang.t('$vuetify.action.update')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import ManagerHelper from "@/helpers/manager";
export default {
  data: function() {
    return {
      dialogState: false,
      managerData: false,
      validator: ManagerHelper.validatorModel(),
      roles: [],
      statuses: []
    };
  },
  methods: {
    validateForm() {
      this.validator = ManagerHelper.validateForm(
        this.managerData.username,
        this.managerData.email,
        'ignore',
        'ignore',
        this.managerData.new_pass,
        this.managerData.re_new_pass
      );
    },
    closePopup() {
      this.dialogState = false;
      this.$emit("closePopup");
      this.validator = ManagerHelper.validatorModel();
    },
    updateManager() {
      this.dialogState = false;
      this.managerData['status'] = this.managerData.status.key
      this.managerData['role'] = this.managerData.role.key

      if(this.managerData.new_pass && this.managerData.new_pass!='' && this.managerData.new_pass!=null) {
        this.managerData['password'] = this.managerData.new_pass;
        delete this.managerData.new_pass
        delete this.managerData.re_new_pass
      }

      Api.manager("update", this.managerData).then(({ data }) => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
        this.$emit("managerUpdated", data);
      });
      this.closePopup();
    }
  },
  watch: {
    popupEditManager(newVal) {
      this.dialogState = newVal;
      this.roles = this.$i18n["messages"][this.$i18n.locale]["$vuetify"][
        "manager_roles"
      ];
      this.statuses = this.$i18n["messages"][this.$i18n.locale]["$vuetify"][
        "statuses"
      ];
    },
    manager(newVal) {
      this.managerData = JSON.parse(JSON.stringify(newVal));
      this.managerData.role = {
        key: newVal.role_key,
        value: newVal.role
      };
      this.managerData.status = {
        key: newVal.status_key,
        value: newVal.status
      };
      this.validator.hasError = false;
    }
  },
  props: ["popupEditManager", "manager"]
};
</script>