<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Table
          :servicePath="'admin/user/list'"
          :headers="tableHeaders"
          :title="tableTitle"
          :itemsPerPageSent="itemsPerPageSent"
          :sortByKey="sortBy"
          :sortDesc="sortDesc"
          icon="mdi-account-multiple"
          tableType="administrators"
          v-on:addManager="addManagerDialog=true"
          v-on:editManager="editManager"
          v-on:activity="activity"
          :reload="reload"
        />
      </v-col>
    </v-row>
    <AddManager
      :popupAddManager="addManagerDialog"
      v-on:managerAdded="reload=reload ? false : true; closePopup"
      v-on:closePopup="closePopup"
    />
    <EditManager
      :popupEditManager="editManagerDialog"
      :manager="editableManager"
      v-on:managerUpdated="reload=reload ? false : true; closePopup"
      v-on:closePopup="closePopup"
    />
    <ActivityLog
      :popupActivity="activityDialog"
      :activityUserId="activityUserID"
      v-on:closePopup="closePopup"
    />
  </div>
</template>

<script>
import Table from "@/components/shared/Table";
import AddManager from "@/components/manager/popup/AddManager";
import EditManager from "@/components/manager/popup/EditManager";
import ActivityLog from "@/components/manager/popup/ActivityLog";
export default {
  components: {
    Table,
    AddManager,
    EditManager,
    ActivityLog
  },
  data: function() {
    return {
      reload: false,
      addManagerDialog: false,
      editManagerDialog: false,
      editableManager: false,
      activityDialog: false,
      activityUserID: false,
      sortBy: ["date"],
      sortDesc: [true],
      itemsPerPageSent: 50
    };
  },
  methods: {
    closePopup() {
      this.addManagerDialog = false;
      this.editManagerDialog = false;
      this.activityDialog = false;
    },
    editManager(i) {
      this.editableManager = i;
      this.editManagerDialog = true;
    },
    activity(id) {
      this.activityUserID = id;
      this.activityDialog = true;
    }
  },
  computed: {
    tableTitle() {
      return this.$vuetify.lang.t("$vuetify.navigations.administrators");
    },
    tableHeaders() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.manager.username"),
          value: "username"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manager.email"),
          value: "email"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manager.status"),
          value: "status"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manager.role"),
          value: "role"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manager.login_date"),
          value: "login_date"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manager.reg_date"),
          value: "insert_date"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.action.action"),
          value: "editAdministrator",
          sortable: false
        }
      ];
    }
  }
};
</script>